/*
pulse: 0.5 seconds

sizes: 30, 20, 10 pixels
100 pixels apart

smallest twists clockwise every half second. 
medium twists every second counter
big every second clockwise, out of phase with medium.

rotation and scale are out of phase by a quarter of a second.
scale is between linear 0.85 to 1.15

---
old:

var left = (100.0 / maxX / (3.2/2.0)) + (x * (100.0 / maxX)) + '%';
var top = (100.0 / maxX / (3.2/2.0)) + (y * (100.0 / maxY)) + '%';
*/

$(document).ready(function() {
    var d = new Date();
    $('.current-year').text(d.getFullYear());

	var maxX = 5;
	var maxY = 5;
	var maxWidth = 2000;
	var maxHeight = 2000;
	var bigBlockWidth = 120;
	var bigBlockHeight = 120;
	var mediumBlockWidth = 80;
	var mediumBlockHeight = 80;
	var smallBlockWidth = 40;
	var smallBlockHeight = 40;
	var iphone = false;
	var ipad = false;
	var mobile = false;
	
	if (isiPhone())
	{
		iphone = true;
		mobile = true;
	}
	else if (isiPad())
	{
		ipad = true;
		mobile = true;
	}
	
	if (!mobile)
	{
		maxX = 6;
		maxY = 6;
		maxWidth = 3300;
		maxHeight = 3300;
	}

    //iphone = true;
	
	if (true) // || !iphone)
	{
		$('.magic-backgrounds').css({
			width: maxWidth + 'px',
			height: maxHeight + 'px',
			top: -1 * (maxHeight / 4.0) + 'px',
			left: -1 * (maxWidth / 4.0) + 'px'
		});
		
		var windowWidth = $(window).width();
		var windowHeight = $(window).height();
		
		$('.magic-backgrounds').css({
			left: (windowWidth / 2.0) - (maxWidth / 2.0) + 'px',
			top: (windowHeight / 2.0) - (maxHeight / 2.0) + 'px'
		});
		
		if (iphone)
		{
			$('.magic-backgrounds').addClass('without-animation');
		} else {
			$('.magic-backgrounds').addClass('with-animation');
		}
		
		for(var x = 0; x < maxX; x++)
		{
			for(var y = 0; y < maxY; y++)
			{
				// -----------------------------------------------
				// Big blocks
				
				var $block = $('<div class="big-block"></div>');
				var left = (x * maxWidth * (1.0 / maxX));
				var top = (y * maxHeight * (1.0 / maxY));
				
				left = left - (bigBlockWidth / 2.0);
				top = top - (bigBlockWidth / 2.0);
				
				$block.css({
					'left': left + 'px',
					'top': top + 'px',
					'width': bigBlockWidth + 'px',
					'height': bigBlockHeight + 'px'
				});
				
				$pulseWrapper = $('<div class="pulse-wrapper"></div>');
				$pulseWrapper.css({
					'width': bigBlockWidth + 'px',
					'height': bigBlockHeight + 'px'
				});
				$block.append($pulseWrapper);
				$('.magic-backgrounds').append($block);
				
				
				// -----------------------------------------------
				// Medium blocks
				
				var $mediumBlock = $('<div class="medium-block"></div>');
				var left = ((x + 0.5) * maxWidth * (1.0 / maxX));
				var top = ((y + 0.5) * maxHeight * (1.0 / maxY));
				
				left = left - (mediumBlockWidth / 2.0);
				top = top - (mediumBlockHeight / 2.0);
				
				$mediumBlock.css({
					'left': left + 'px',
					'top': top + 'px',
					'width': mediumBlockWidth + 'px',
					'height': mediumBlockHeight + 'px'
				});
				
				$pulseWrapper = $('<div class="pulse-wrapper"></div>');
				$pulseWrapper.css({
					'width': mediumBlockWidth + 'px',
					'height': mediumBlockHeight + 'px'
				});
				$mediumBlock.append($pulseWrapper);
				$('.magic-backgrounds').append($mediumBlock);
				
				if (mobile)
				{
					continue;
				}
				
				// -----------------------------------------------
				// Small blocks TOP LEFT
				
				var $smallBlock = $('<div class="small-block"></div>');
				var left = ((x + 0) * maxWidth * (1.0 / maxX));
				var top = ((y + 0.5) * maxHeight * (1.0 / maxY));
				
				left = left - (smallBlockWidth / 2.0);
				top = top - (smallBlockHeight / 2.0);
				
				$smallBlock.css({
					'left': left + 'px',
					'top': top + 'px',
					'width': smallBlockWidth + 'px',
					'height': smallBlockHeight + 'px'
				});
				
				$pulseWrapper = $('<div class="pulse-wrapper"></div>');
				$pulseWrapper.css({
					'width': smallBlockWidth + 'px',
					'height': smallBlockHeight + 'px'
				});
				$smallBlock.append($pulseWrapper);
				$('.magic-backgrounds').append($smallBlock);
				
				// -----------------------------------------------
				// Small blocks TOP RIGHT
				
				var $smallBlock = $('<div class="small-block"></div>');
				var left = ((x + 0.5) * maxWidth * (1.0 / maxX));
				var top = ((y + 0) * maxHeight * (1.0 / maxY));
				
				left = left - (smallBlockWidth / 2.0);
				top = top - (smallBlockHeight / 2.0);
				
				$smallBlock.css({
					'left': left + 'px',
					'top': top + 'px',
					'width': smallBlockWidth + 'px',
					'height': smallBlockHeight + 'px'
				});
				
				$pulseWrapper = $('<div class="pulse-wrapper"></div>');
				$pulseWrapper.css({
					'width': smallBlockWidth + 'px',
					'height': smallBlockHeight + 'px'
				});
				$smallBlock.append($pulseWrapper);
				$('.magic-backgrounds').append($smallBlock);
			}
		}
	} else {
		$('.magic-backgrounds').remove();
	}

    $('.headline-quote').cycle({
        'fx': 'fade',
        'speed': 500
    });
	/*
	*/
	
	/*
    $('#screenshots-block').cycle({
        'fx': 'fade',
        'speed': 500
    });
	*/

	if (isiPhone()) {
        //$('body').append('<meta name="viewport" content="user-scalable=no, width=device-width;" />');
        $('body').append('<meta name="viewport" content="user-scalable=no, width=640;" />');

	} else if (isiPad()) {
		document.body.parentElement.className = "is-ipad";
        document.body.className = "is-ipad";
        
        $('body').append('<meta name="viewport" content="user-scalable=no, initial-scale=0.9;" />');

	} else {
		document.body.parentElement.className = "is-desktop";
		document.body.className = "is-desktop";
	}
	
    /*
    var $iframe = $('<iframe src="https://player.vimeo.com/video/75464291" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>');

	$iframe.attr('width', '640');
	$iframe.attr('height', '360');

    setTimeout(function() {
        $('#iphone').append($iframe);
    }, 0.5 * 1000);
    */

	/*
    var desiredLaunchTime = 2357693200;
    var foo = new Date();
    var timeDiff = parseFloat( (0 * 24 * 60 * 60) + (foo.getTime())/1000) < parseFloat(desiredLaunchTime);
    
	if (timeDiff)
	{
        $('#purchase').html('<p class="coming-soon">Coming Soon<br><a id="coming-soon-link" href="#showmailform">Keep me in the loop</a></p>');
		$('#coming-soon-link').click(function() {
			$('.mail-sign-up-link:first').trigger('click');
		});
    }
    */

	/*
    $('.award').css('opacity', '0');

    $(window).scroll(function(e) {
        awardsScrollCheck();
    }).trigger('scroll');

    $(document).bind('scroll', function(e) {
        awardsScrollCheck();
    }).trigger('scroll');
	*/

	//demo of the iOS 7 Homescreen Parallax effect, using web tech
	//Detailed article about how it's done at http://www.webdirections.org/blog/parallax

	var xOffset = 0;
	var yOffset = 0;
	//a calculated offset for the x and y origins
	//global var to keep it really simple for the demo, I know not ideal!

	function orientationChanged (orientationEvent) {
		var beta = orientationEvent.beta;
		var gamma = orientationEvent.gamma;
		//get the rotation around the x and y axes from the orientation event
		
		if (window.orientation !== null) {
			//don't check for truthiness as window.orientation can be 0!
			var screenOrientation = window.orientation;
		
			if (screenOrientation === -90) {
				//rotated to the left 90 degrees
				
				beta = orientationEvent.gamma
				gamma = -1 * orientationEvent.beta
			}

			if (screenOrientation === 90) {
				beta = -1 * orientationEvent.gamma
				gamma = orientationEvent.beta

			}
		
			if (screenOrientation === 180) {
				beta = -1 * orientationEvent.beta
				gamma = -1 * orientationEvent.gamma
			}
		}
		
		var tanOfGamma = Math.tan(gamma*(Math.PI/180))
		var tanOfBeta = Math.tan((beta -45)*(Math.PI/180))
		//calculate the tan of the rotation around the X and Y axes
		//we treat beta = 45degrees as neutral
		//Math.tan takes radians, not degrees, as the argument
		
		var backgroundDistance = 80; //document.querySelector("#distance").value //50 
		//set the distance of the background from the foreground
		//the smaller, the 'closer' an object appears
		
		var xImagePosition = (1 * tanOfGamma * backgroundDistance) + xOffset;
		var yImagePosition = (1 * tanOfBeta * backgroundDistance) + yOffset;
		//calculate the distance to shift the background image horizontally

		//adjustMagicBackgrounds(xImagePosition, yImagePosition);
		//set the backgroundimage position to  xImagePosition yImagePosition
	}

	var $magicBackgrounds = $('.magic-backgrounds');

	function adjustMagicBackgrounds(x, y) {
		//$magicBackgrounds.css('margin-left', x + 'px');
		//$magicBackgrounds.css('margin-top', y + 'px');
	}

	//$(window).bind('deviceOrientation', orientationChanged);
	window.addEventListener("deviceorientation", orientationChanged, false)

	var imageWidth = 1024;
	var imageHeight = 1024;
	xOffset = parseInt($magicBackgrounds.css('left')); //-1 * (imageWidth - elRect.width)/2
	yOffset = parseInt($magicBackgrounds.css('top')); //-1 * (imageHeight - elRect.height)/2

	xOffset = 0;
	yOffset = 0;

	//adjustMagicBackgrounds(xOffset, yOffset);

	var dx = null;
	var dy = null;
	var lastx = null;
	var lasty = null;
	var sx = null;
	var sy = null;

	$(window).mousemove(function(e) {
		//console.log(e.clientX);
		//console.log(e.clientY);

		var w = $(window).width();
		var h = $(window).height();

		if (dx === null) {
			dx = e.clientX;
			sx = e.clientX;
		} else {
			dx = lastx - e.clientX;
			lastx = e.clientX;
		}

		if (dy === null) {
			dy = e.clientY;
			sy = e.clientY;
		} else {
			dy = lasty - e.clientY;
			lasty = e.clientY;
		}

		sx = sx - dx;
		sy = sy - dy;

		var x = 64 * ((sx) - (w/2.0)) / w;
		var y = 64 * ((sy) - (h/2.0)) / h;

		//adjustMagicBackgrounds(x, y);
	});

});

/*
function awardsScrollCheck() {
    $('.award').each(function() {
        if (checkVisible(this))
        {
            $(this).animate({
                'opacity': '1'
            }, 800 + 500 * Math.random());
        }
    });
}

function checkVisible(elm) {
    var vpH = $(window).height(), // Viewport Height
        st = $(window).scrollTop(), // Scroll Top
        y = $(elm).offset().top;

    //return (y < (vpH + st));
    return y < (st - vpH);
}
*/

function isiPhone(){
    return (
        (navigator.platform.indexOf("iPhone") != -1) ||
        (navigator.platform.indexOf("iPod") != -1)
	);
}

function isiPad() {
	return (
		(navigator.platform.indexOf("iPad") != -1)
    );
}
